<template>
    <section class="position-relative d-flex overflow-hidden application">
        <b-container>
            <h2 class="page-h1 mx-0 mb-0  ">{{ $t("Nav_application") }}</h2>

            <b-row class="justify-content-between">
                <b-col class="swiper-container-application w-100">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <router-link to="/application" class="application-info d-flex">
                                <div class="photo-wrapper">
                                    <div class="photo safe" loading="lazy"></div>
                                </div>
                                <div class="infoBox">
                                    <h3 class="slogan mx-0 mb-0">{{ $t('Application_Home.Safety') }}</h3>
                                    <p class="description mx-0 mb-0" v-html="$t('Application_Home.Safety_Description')">
                                    </p>
                                </div>
                            </router-link>
                        </div>

                        <div class="swiper-slide">
                            <router-link to="/application" class="application-info d-flex">
                                <div class="photo-wrapper">
                                    <div class="photo digital" loading="lazy"></div>
                                </div>
                                <div class="infoBox">
                                    <h3 class="slogan mx-0 mb-0">{{ $t('Application_Home.Digital') }}</h3>
                                    <p class="description mx-0 mb-0" v-html="$t('Application_Home.Digital_Description')">
                                    </p>
                                    </div>
                            </router-link>
                        </div>

                        <div class="swiper-slide">
                            <router-link to="/application" class="application-info d-flex">
                                <div class="photo-wrapper">
                                    <div class="photo business" loading="lazy"></div>
                                </div>
                                <div class="infoBox">
                                    <h3 class="slogan mx-0 mb-0">{{ $t('Application_Home.Business') }}</h3>
                                    <p class="description mx-0 mb-0" v-html="$t('Application_Home.Digital_Description')">
                                    </p>
                                </div>
                            </router-link>
                        </div>

                        <div class="swiper-slide">
                            <router-link to="/application" class="application-info d-flex">
                                <div class="photo-wrapper">
                                    <div class="photo life" loading="lazy"></div>
                                </div>
                                <div class="infoBox">
                                    <h3 class="slogan mx-0 mb-0">{{ $t('Application_Home.Life') }}</h3>
                                    <p class="description mx-0 mb-0" v-html="$t('Application_Home.Life_Description')">
                                    </p>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>
                </b-col>
            </b-row>
            
            <div class="more-wrapper" >
                <BaseMore :lead-to="'/application'" /> 
            </div>
        </b-container>
    </section>
</template>

<script>
import Swiper, { Navigation, Pagination }  from 'swiper';
Swiper.use([Navigation, Pagination]);

export default {
    data() {
        return {

        }
    },

    mounted() {
        this.setSlide();
    },

    methods: {
        setSlide() {
            // eslint-disable-next-line
            let swiper_aira;

            const swiperContainer = document.querySelector('.swiper-container-application');

            swiper_aira = new Swiper(swiperContainer, {
                    slidesPerView: 1,
                    spaceBetween: 10,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    // Responsive breakpoints
                    breakpoints: {
                        812: {
                            slidesPerView: 2,
                            spaceBetween: 16,
                            pagination: {
                                el:'.swiper-pagination',
                                clickable: true,
                            },
                        },
                        // when window width is >= 1024px
                        1024: {
                            slidesPerView: 2,
                            watchOverflow:true,
                            pagination: {
                                el:'.swiper-pagination',
                                clickable: true,
                            },
                        },
                        1440: {
                            slidesPerView: 4,
                            spaceBetween: 24,
                            pagination: {
                                el: null,
                            },
                        },
                    }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.swiper-container-application{
    margin-left: -5px;
    margin-right: -5px;
}

.application {
    margin-bottom: 60px;
}

.application-info{
    width: 100%;
    flex-wrap: wrap;
    transition: box-shadow 0.3s ease;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    &:hover{
        color: #fff;
        box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.17);

        .slogan {
            color: $color_secondary;
        }

        .photo {
            transform: scale(1.05);
        }
    }
}

.infoBox {
    height: 258px;
    background-color: #f8f8f8;
    width: 344px;
}

.slogan {
    font-size: 24px;
    padding-top: 20px;
    line-height: 2.5;
    text-align: left;
    color: $color_dark_title;
    padding-right: 15px;
    padding-left: 15px;
    justify-content: left;
    font-weight: 400;
    transition: color ease 0.3s;
}

.photo-wrapper{
    width: 100%;
    overflow: hidden;
}

.photo {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 344px;
    height: 258px;
    transform: scale(1);
    transition: transform  0.3s ease;
    &.safe {
        background-image: url("~@/assets/img/application/safe.png");
    }

    &.digital {
        background-image: url("~@/assets/img/application/digital.png");
    }

    &.business {
        background-image: url("~@/assets/img/application/business.png");
    }

    &.life {
        background-image: url("~@/assets/img/application/life.png");
    }
}

.more-wrapper {
    text-align: center;
    margin-top: 40px;
}


@media screen and (max-width: 1440px) {
    .application-info{
        flex-wrap: nowrap;
    }
}

@media screen and (max-width: 1024px) {
    .infoBox {
        width: 100%;
    }

    .description {
        font-size: 16px;
        min-height: 96px;
    }

    .photo {
        width: 100%;
        height: 258px;
    }
}

@media screen and (max-width: 812px) {
    .application-info{
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 576px) {
    .infoBox {
        padding-bottom: 25px;
        height: auto;
        min-height: 195px;
    }

    .photo {
        height: 230px;
    }

    .description {
        font-size: 16px;
        min-height: 75px;
    }

    .slogan {
        padding-top: 15px;
        font-size: 22px;
    }

    .more-wrapper {
        margin-top: 25px;
    }
}
</style>