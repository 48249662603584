<template>
    <section :class="[tabs && tabs.length > 0 ? 'd-flex' : 'd-none']" class="position-relative overflow-hidden events-section">
        <b-container>
            <h2 class="page-h1  mx-0 mb-0">{{ $t("Nav_event") }}</h2>
            <div class="  position-relative">  
                <div class="tabs">
                    <!-- arrow -->
                    <div class="mobile-event-tab-control">
                        <div class="tab-control-left tab-control-btn" @click="setTabIndex(-1)">
                            <b-icon icon="chevron-left"></b-icon>
                        </div>
                        <div class="tab-control-right tab-control-btn" @click="setTabIndex(1)">
                            <b-icon icon="chevron-right"></b-icon>
                        </div>
                    </div>

                    <ul class="nav justify-content-center event-tabs">
                        <li v-for="({value, text}, index) in tabs" :key="value">
                            <div
                                @click="clickTab(index)"
                                :class="{'active': tabIndex == index}"
                                class="nav-link event-link">
                                <h3 class="tab-title">{{ text[currentLang] }}</h3>
                            </div>
                        </li>
                    </ul>
                </div>

                <div v-show="eventList && eventList.length > 0" class="swiper-container-events overflow-hidden position-relative w-100">
                    <base-loading :is-loading="isLoading" :use-white-bg="true" />

                    <div class="swiper-wrapper">
                        <a v-for="event in eventList"
                            :key="event._id"
                            class="swiper-slide"
                            :href="`/events/${event.category[currentLang]}/${event[currentLang].title}`">
                            <div  class="event-item position-relative overflow-hidden">
                                <img
                                    class="event-image"
                                    :src="event.cover"
                                    :alt="event[currentLang].title" loading="lazy"
                                    >
                                <h4 class="event-item-title">{{ event[currentLang].title }}</h4>
                                <div class="event-more">
                                    <span>{{ $t('MoreEventDetail') }}</span>
                                    <b-icon class="more-icon" icon="chevron-right"></b-icon>
                                </div>
                            </div>
                        </a>
                    </div>

                    <!-- dots -->
                    <div class="swiper-pagination"></div>
                </div>

                <!-- navigation -->
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>

                <div class="more-wrapper" >
                    <BaseMore :lead-to="'/events'">
                        {{ $t('MoreEvent') }}
                    </BaseMore> 
                </div>
            </div>
        </b-container>
    </section>
</template>

<script>
import Swiper, { Navigation, Pagination, Autoplay, Lazy }  from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay, Lazy]);
import { getCategories } from '@/apis/apiEventCategories';
import { getEvents } from '@/apis/apiEvents';

export default {
    data() {
        return {
            tabIndex: 0,
            tabs: [],
            eventList: [],
            swiper_aira: null,
            isLoading: false,
        }
    },

    watch: {},

    computed: {
        currentLang() {
            const locale = this.$i18n.locale;
            
            if (locale.includes('en')) {
                return 'en';
            } else {
                return 'zh';
            }
        }
    },

    created() {
        this.isLoading = true;
    },

    mounted() {
        this.initialView()
    },

    methods: {
        setSlide() {
            // eslint-disable-next-line
            this.swiper_aira = null;
            let swiper_aira;

            const swiperContainer = document.querySelector('.swiper-container-events');

            swiper_aira = new Swiper(swiperContainer, {
                slidesPerView: 1,
                spaceBetween: 6,
                centeredSlides: true,
                loop: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                // Responsive breakpoints
                breakpoints: {
                    // when window width is >= 870px
                    870: {
                        slidesPerView: 2.2,
                        spaceBetween: 16,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true,
                        },
                    }
                }
            });
        
            this.swiper_aira = swiper_aira;
        },

        setTabIndex(state) {
            const maxIndex = this.tabs.length - 1;
            let tmpIndex = this.tabIndex + state;

            if (tmpIndex < 0) {
                tmpIndex = maxIndex;
            } else if (tmpIndex > maxIndex) {
                tmpIndex = 0
            }

            this.tabIndex = tmpIndex;
            this.clickTab(tmpIndex);
        },

        async initialView() {
            const categories = await getCategories();
            const category = categories && categories[0] ? categories[0].value : '';
            
            this.tabs = categories;

            const eventData = await getEvents({
                currentPage: 1,
                perPageItems: 3,
                category, 
            });

            this.eventList = eventData.eventList;

            // timer change tab
            // this.tabCarousel();

            this.$nextTick(() => {
                this.setSlide();
            });

            this.isLoading = false;
        },

        async clickTab(index) {
            this.tabIndex = index;

            const category =  this.tabs[index] ? this.tabs[index]['value'] : '';
            const eventData = await getEvents({
                currentPage: 1,
                perPageItems: 3,
                category, 
            });

            this.eventList = eventData.eventList;
            this.swiper_aira.slideToLoop(0, false, false);

            this.$nextTick(() => {
                this.setSlide();
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.swiper-container-events{
    position: relative;
}

.swiper-button-next,
.swiper-button-prev {
    color: #d0d0d0;
    position: absolute;
    top: 50%;
}

.swiper-button-prev {
    left: -40px;
}

.swiper-button-next {
    right: -40px;
}

.events-section{
    position: relative;
    margin-bottom: 70px;
}

.tab-title{
    font-size: 24px;
    margin-top: -8px;
    color: #747474;
    padding-top: 0;
    font-weight: 400;
    position: relative;
    margin-bottom: 20px;
    transition: color 0.2s ease;
    font-family: $font-family;
}

.active .tab-title{
    color: $color_primary;
    position: relative;
    &:after {
        content:"";
        position: absolute;
        border-bottom: 3px solid;
        left: 0;
        bottom: -10px;
        display: block;
        width: 100%;
    }
}

.event-link{
    cursor: pointer;
}

.event-image{
    object-fit: cover;
    object-position: center center;
    width: auto;
    min-width: 100%;
    height: 360px;
}

.event-item-title{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    white-space:nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    color: #fff;
    background: rgba(0,0,0, 0.7);
    margin-bottom: 0;
    padding: 20px 20px;
    font-size: 20px;
    font-weight: 400;
}

.event-more {
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -100%);
    opacity: 0;
    left: 50%;
    transition: transform 0.2s ease;
    z-index: 3;
    .more-icon {
        position: relative;
        left: 0;
        transition: left 0.1s ease;
    }
    &:hover {
        color: #fff;
        .more-icon {
            left: 6px;
        }
    }
}

.event-item {
    cursor: pointer;
    &:hover {
        .tab-title {
            color:  $color_primary;
        }
    }

    &:hover .event-more {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
    &:after {
        content: '';
        display: block;
        opacity: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        transition: opacity 0.15s ease;
    }

    &:hover:after {
        opacity: 1;
    }
}

.swiper-slide{
    display: block;

    .event-item {
        display: inline-block;
        margin: 8px;
        width: 100%;
    }
}

.more-wrapper {
    text-align: center;
    margin-top: 15px;
}

.mobile-event-tab-control{
    display: none;
}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 812px) {
    .swiper-slide{
        .event-item {
            margin: 8px 0;
            width: 100%;
            height: 250px !important; 
        }
    }

    .event-more{
        display: none;
    }

    .event-item{
        &:after {
            display: none;
        }
    }

    .event-item-title{
        padding: 15px 16px;
        font-size: 18px;
    }
}

@media screen and (max-width: 576px) {
    .mobile-event-tab-control{
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    .tab-control-btn{
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        top: 0;
        z-index: 3;
        cursor: pointer;
    }

    .tab-control-right{
        right: 0;
    }

    .tab-control-left{
        left:0;
    }

    .nav-link{
        padding: 0.5rem;
    }

    .event-item {
        &:hover {
            .tab-title {
                color:  transparent;
            }

            .active .tab-title{
                color: $color_primary;
            }
        }
    }

    .tab-title {
        color: transparent;
        width: 12px;
        height: 30px;
        transition: none;
        &:after {
            content:"";
            position: absolute;
            border-bottom: 3px solid #e6e6e6;
            left: 0;
            bottom: -10px;
            display: block;
            width: 100%;
        }
    }

    .active .tab-title {
        width: auto;
    }
}
</style>