<template>
    <section class="position-relative d-flex overflow-hidden whyaira">
        <b-container>
            <h2 class="page-h1 mx-0 mb-0  ">{{ $t("Nav_whyaira") }}</h2>
            <b-row>
                <b-col class="swiper-container-whyaira w-100">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="photo taiwan-rnd">
                                <img src="~@/assets/img/whyaira/taiwan-rnd.png" :alt="$t('Whyaira_Innovation')" loading="lazy">
                            </div>
                            <h3 class="slogan">{{$t('Whyaira_Innovation') }}</h3>
                            <h4 class="description mb-0">{{$t('Whyaira_Innovation_description') }}</h4>
                        </div>
                        <div class="swiper-slide">
                            <div class="photo professional">
                                <img src="~@/assets/img/whyaira/professional.png" :alt="$t('Whyaira_Professional')" loading="lazy">
                            </div>
                            <h3 class="slogan">{{$t('Whyaira_Professional') }}</h3>
                            <h4 class="description mb-0">{{$t('Whyaira_professional_description') }}</h4>
                        </div>
                        <div class="swiper-slide">
                            <div class="photo">
                                <img src="~@/assets/img/whyaira/technowledge.png" :alt="$t('Whyaira_Technology')" loading="lazy">
                            </div>
                            <h3 class="slogan">{{$t('Whyaira_Technology') }}</h3>
                            <h4 class="description mb-0">{{$t('Whyaira_technology_description') }}</h4>
                        </div>

                        <div class="swiper-slide">
                            <div class="photo intergration">
                                <img src="~@/assets/img/whyaira/intergration.png" :alt="$t('Whyaira_Intergration')" loading="lazy">
                            </div>
                            <h3 class="slogan">{{$t('Whyaira_Intergration')}}</h3>
                            <h4 class="description mb-0">{{$t('Whyaira_technology_description')}}</h4>
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
import Swiper, { Navigation, Pagination }  from 'swiper';
Swiper.use([Navigation, Pagination]);

export default {
    data() {
        return {}
    },

    mounted() {
        this.setSlide();
    },

    methods: {
        setSlide() {
            // eslint-disable-next-line
            let swiper_aira;

            const swiperContainer = document.querySelector('.swiper-container-whyaira');

            swiper_aira = new Swiper(swiperContainer, {
                slidesPerView: 1,
                spaceBetween: 16,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                breakpoints: {
                    812: {
                        slidesPerView: 2,
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true,
                        },
                    },
                    1024: {
                        slidesPerView: 2,
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true,
                        },
                    },
                    1440: {
                        slidesPerView: 4,
                        pagination: {
                            el: null,
                        },
                    }
                },
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.whyaira {
    width: 100%;
    object-fit: contain;
    background-color: $light-gray-color;
    padding-bottom: 100px;
}

.swiper-slide{
    display: block;
}

.photo{ 
    img {
        height: 255px;
        display: inline-block;
        width: 100%;
        object-fit: cover;
    }
}

.description {
    font-size: 16px;
    min-height: 96px;
}

.slogan {
    font-size: 24px;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 15px;
    font-weight: 400;
}

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 1024px) {
      .description {
        min-height: auto;
    }
}

@media screen and (max-width: 812px) {}

@media screen and (max-width: 576px) {
    .whyaira {
        padding-bottom: 65px;
    }
}

</style>