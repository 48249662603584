<template>
    <div class="home">
        <base-nav />

        <home-banner />

        <h1 class="wdn-text-hidden">{{ $t('Page_title')}} </h1>

        <a class="home-link" id="application" href="javascript:;">
            <div class="wdn-text-hidden">人臉辨識系統廠商_場域應用</div>
        </a>
        <home-application />

        <a class="home-link" id="solution"  href="javascript:;">
            <div class="wdn-text-hidden">人臉辨識系統廠商_解決方案</div>
        </a>
        <home-solution />

        <a class="home-link" id="event"  href="javascript:;">
            <div class="wdn-text-hidden">人臉辨識系統廠商_活動紀錄</div>
        </a>
        <home-events />

        <a class="home-link" id="whyaira"  href="javascript:;">
            <div class="wdn-text-hidden">人臉辨識系統廠商_關於城智科技</div>
        </a>
        <home-whyaria />

        <a class="home-link" id="partner"  href="javascript:;">
            <div class="wdn-text-hidden">人臉辨識系統廠商_合作夥伴</div>
        </a>

        <a class="home-link" id="partner"  href="javascript:;">
            <div class="wdn-text-hidden">人臉辨識系統廠商_Intel 認證Gold Partner</div>
        </a>
        <home-partner />

        <base-contact/>
        <base-footer /> 
    </div>
</template>

<script>
// @ is an alias to /src
import HomeBanner from '@/components/home/HomeBanner.vue';
import HomeApplication from '@/components/home/HomeApplication.vue';
import HomeSolution from '@/components/home/HomeSolution.vue';
import HomeWhyaria from '@/components/home/HomeWhyaria.vue';
import HomeEvents from '@/components/home/HomeEvents.vue';
import HomePartner from '@/components/home/HomePartner.vue';
import BaseContact from '@/components/global/BaseContact.vue'

export default {
    name: 'Home',

    metaInfo() {
        return {
            title: this.$t('Head_title'),
            meta: [
                {
                    name: 'description',
                    content: this.$t('Head_description')
                } ,{
                    name: 'keywords',
                    content: this.$t('Head_keywords')
                }, {
                    name: 'author',
                    content: this.$t('Head_author')
                }, {
                    name: 'copyright',
                    content: this.$t('Head_copyright')
                }
            ]
        }
    },

    components: {
        HomeBanner,
        HomeApplication,
        HomeSolution,
        HomeWhyaria,
        HomeEvents,
        HomePartner,
        BaseContact
    },

    data() {
        return {
        }
    }

}
</script>

<style lang="scss" scoped>
a.home-link {
    color: #0071eb;
}
</style>