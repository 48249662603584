<template>
    <section class="position-relative d-flex overflow-hidden solution w-100">
        <b-container >
            <h2 class="page-h1  ">{{ $t("Nav_solution") }}</h2>
            <b-row no-gutters>
                <b-col class="d-flex">
                    <div class="solution-items">
                        <div class="swiper-wrapper">
                        <div class="flex-column w-100">
                            <div class="underline-grey caption photo-height">&nbsp;</div>
                            <div class="sol-item-cap left-caption item-height1">{{ $t('Solution.Cap_Scenario') }}</div>
                            <div class="sol-item-cap left-caption item-height2">{{ $t('Solution.Cap_Application') }}</div>
                            <div class="sol-item-cap left-caption item-height3">{{ $t('Solution.Cap_Hardware') }}</div>
                        </div>
                        </div>
                    </div>
                    <div class="solution-data">
                        <div class="swiper-container-solution w-100">
                            <div class="swiper-wrapper">
                                <!-- 防疫型方案  -->
                                <div class="swiper-slide flex-column">
                                    <div class="sol-item cap-photo temperature"></div>
                                    <h3 class="sol-item underline-grey d-flex caption">{{ $t('Solution.Anti_Epidemic.Caption') }}</h3>
                                    <h4 class="item-height1 description" v-html="$t('Solution.Anti_Epidemic.Scenario')"></h4>
                                    <div class="item-height2 description">
                                        <ul>
                                            <li>{{ $t('Solution.Anti_Epidemic.Application_01') }}</li>
                                            <li>{{ $t('Solution.Anti_Epidemic.Application_02') }}</li>
                                            <li>{{ $t('Solution.Anti_Epidemic.Application_03') }}</li>
                                        </ul>
                                    </div>
                                    <div class="item-height3 description">
                                        <ul>
                                            <li>{{ $t('Solution.Anti_Epidemic.Hardware_01') }}</li>
                                            <li>{{ $t('Solution.Anti_Epidemic.Hardware_02') }}e</li>
                                            <li>{{ $t('Solution.Anti_Epidemic.Hardware_03') }}</li>
                                        </ul>
                                    </div>
                                </div>

                                <!-- 實名制方案  -->
                                <div class="swiper-slide flex-column">
                                    <div class="sol-item cap-photo visitor"></div>
                                    <h3 class="sol-item underline-grey d-flex caption">{{ $t('Solution.Real_Name.Caption') }}</h3>
                                    <h4 class="item-height1 description" v-html="$t('Solution.Real_Name.Scenario')"></h4>
                                    <div class="item-height2 description">
                                        <ul>
                                            <li>{{ $t('Solution.Real_Name.Application_01') }}</li>
                                            <li>{{ $t('Solution.Real_Name.Application_02') }}</li>
                                            <li>{{ $t('Solution.Real_Name.Application_03') }}</li>
                                        </ul>
                                    </div>
                                    <div class="item-height3 description">
                                        <ul>
                                            <li>{{ $t('Solution.Real_Name.Hardware_01') }}</li>
                                            <li>{{ $t('Solution.Real_Name.Hardware_02') }}</li>
                                        </ul>
                                    </div>
                                </div>

                                <!-- 多人動態方案 -->
                                <div class="swiper-slide flex-column">
                                    <div class="sol-item cap-photo multiplayer"></div>
                                    <h3 class="sol-item underline-grey d-flex caption">{{ $t('Solution.Multiple_People.Caption') }}</h3>
                                    <h4 class="item-height1 description" v-html="$t('Solution.Multiple_People.Scenario')"></h4>
                                    <div class="item-height2 description">
                                        <ul>
                                            <li>{{ $t('Solution.Multiple_People.Application_01') }}</li>
                                            <li>{{ $t('Solution.Multiple_People.Application_02') }}</li>
                                            <li>{{ $t('Solution.Multiple_People.Application_03') }}</li>
                                        </ul>
                                    </div>
                                    <div class="item-height3 description">
                                        <ul>
                                            <li>{{ $t('Solution.Multiple_People.Hardware_01') }}</li>
                                            <li>{{ $t('Solution.Multiple_People.Hardware_02') }}</li>
                                            <li>{{ $t('Solution.Multiple_People.Hardware_03') }}</li>
                                        </ul>
                                    </div>
                                </div>

                                <!-- 行動方案 -->
                                <div class="swiper-slide flex-column">
                                    <div class="sol-item cap-photo actionplan"></div>
                                    <h3 class="sol-item underline-grey d-flex caption">{{ $t('Solution.Mobility.Caption') }}</h3>
                                    <h4 class="item-height1 description" v-html="$t('Solution.Mobility.Scenario')"></h4>
                                    <div class="item-height2 description">
                                        <ul>
                                            <li>{{ $t('Solution.Mobility.Application_01') }}</li>
                                            <li>{{ $t('Solution.Mobility.Application_02') }}</li>
                                            <li>{{ $t('Solution.Mobility.Application_03') }}</li>
                                        </ul>
                                    </div>
                                    <div class="item-height3 description">
                                        <ul>
                                            <li>{{ $t('Solution.Mobility.Hardware_01') }}</li>
                                            <li>{{ $t('Solution.Mobility.Hardware_02') }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="swiper-pagination"></div>
                            <!-- Add Arrows -->
                            <!-- <div class="swiper-button-next"></div>
                            <div class="swiper-button-prev"></div> -->
                        </div>

                        
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
import Swiper, { Navigation, Pagination }  from 'swiper';
Swiper.use([Navigation, Pagination]);

export default {
    data() {
        return {}
    },

    mounted() {
        this.setSlide();
    },

    methods: {
        setSlide() {
            // eslint-disable-next-line
            let swiper_aira;
            const swiperContainer = document.querySelector('.swiper-container-solution');

            swiper_aira = new Swiper(swiperContainer, {
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: {
                    el:'.swiper-pagination',
                    clickable: true,
                },
                // Responsive breakpoints
                breakpoints: {
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                        pagination: {
                            el:'.swiper-pagination',
                            clickable: true,
                        },
                    },
                    1440: {
                        slidesPerView: 4,
                        spaceBetween: 0,
                    }
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.solution {
    object-fit: contain;
    background-color: $light-gray-color;
}

.item-height1 {
    height: 130px;
}
.item-height2 {
    height: 160px;
}

.item-height3 {
    height: 160px;
}

.sol-item {
    width: 100%;
}

.sol-item-cap {
    white-space: nowrap;
    padding-right: 40px;
    width: 100%;
    &:nth-child(2n+1) {
        background: #fff;
    }
    &:nth-child(2n) {
        background: $light-gray-color;
    }
}

.caption {
    font-size: 20px;
    color: #222222;
    padding-left: unset;
    padding-bottom: 6px;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    line-height: 1.5em;
    font-weight: 400;
}

.solution-data {
    overflow: hidden;
}

.left-caption {
    font-size: 20px;
    color: #222222;
    padding-left: unset;
    justify-content: center;
    padding-left: 20px;
    padding-top: 16px;
}

h4.description {
    margin-bottom: 0;
}

.description {
    font-size: 16px !important;
    margin-left: 0px;
    padding: 13px;
    text-align: left !important;
    width: 100%;
    &:nth-child(2n) {
        background:#fff;
    }
    ul {
        padding-left: 20px;
    }
}

.underline {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    background-clip: padding-box;
}

.underline-grey {
    border-bottom: 1px solid #d8d8d8;
    background-clip: padding-box;
}

.photo-height{
    height: 142px;
}

.cap-photo {
    width: 90px;
    height: 90px;
    margin-bottom: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    
    &.temperature {
    background-image: url("~@/assets/img/solution/temperature.png");
    }

    &.visitor {
        background-image: url("~@/assets/img/solution/visitor.png");
    }

    &.biological {
        background-image: url("~@/assets/img/solution/biological.png");
    }

    &.multiplayer {
        background-image: url("~@/assets/img/solution/multiplayer.png");
    }

    &.actionplan {
        background-image: url("~@/assets/img/solution/actionplan.png");
    }
}

.swiper-button-next,
.swiper-button-prev {
    display: none;
    color: #d0d0d0;
}

@media screen and (max-width: 1440px) {
    .swiper-button-next,
    .swiper-button-prev {
        display: block;
        top: 46%;
    }

    .swiper-button-prev{
        left: -40px;
    }

    .swiper-button-next{
        right: -40px;
    }

}

@media screen and (max-width: 1200px) {
    .swiper-button-prev{
        left: -10px;
    }

    .swiper-button-next{
        right: -10px;
    }
}

@media screen and (max-width: 812px) {
    .solution-data {
        width: 83%;
    }

    .solution-items {
        width: 17%;
    }
}

@media screen and (max-width: 576px) {
    .solution-items{
        width: 110px;
    }

    .solution-data{
        width: calc(100% - 110px);
        z-index: 1;
    }

    .left-caption{
        font-size: 18px;
        padding-left: 18px;
    }

    .photo-height {
        height: 112px;
    }

   .cap-photo {
        width: 70px;
        height: 70px;
        margin-bottom: 5px;
    }

    .swiper-button-prev{
        left: -12px;
    }

    .swiper-button-next{
        right: -12px;
    }
}
</style>