<template>
    <div class="position-relative d-flex overflow-hidden about" loading="lazy">
        <b-container class="d-flex align-items-center">
            <b-row align-v="center">
                <img 
                    class="banner-slogan-pc"
                    :src="sloganImagePc"
                    :alt="$t('Banner_Image_slogan')" loading="lazy">
                <img
                    class="banner-slogan-mobile"
                    :src="sloganImageMobile"
                    :alt="$t('Banner_Image_slogan')" loading="lazy">
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },

    computed: {
        sloganImagePc() {
            const locale = this.$i18n.locale;

            if (locale.includes('en')) {
                return require('@/assets/img/1920/banner/main-bg-text-en.png')
            } 

            return require('@/assets/img/1920/banner/main-bg-text-zh.png');
        },

        sloganImageMobile() {
            const locale = this.$i18n.locale;

            if (locale.includes('en')) {
                return require('@/assets/img/375/banner/main-bg-text-en.png')
            } 

            return require('@/assets/img/375/banner/main-bg-text-zh.png');
        }
    },
}
</script>

<style lang="scss" scoped>
.about {
  width: 100%;
  object-fit: contain;
}

.about .container {
  flex-direction: row;
  padding: 0px;
  margin: auto;
  position: relative;
}

.about {
  height: 570px;
  margin-top: 0;
  background-image: url("~@/assets/img/1920/banner/main-bg.webp");
  background-repeat: no-repeat;
  background-position: center;
}

.banner-slogan-pc{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-40%);
}

.banner-slogan-mobile{
  position: absolute;
  display: none;
  right: 0;
  bottom:0;
}

@media screen and (max-width: 1024px) {
    .about {
        height: 450px;
        margin-top: 0;
        background-image: url("~@/assets/img/1280/banner/main-bg.webp");
        background-repeat: no-repeat;
        background-position: center;
    }

    .banner-slogan-pc{
        position: absolute;
        right: -20px;
        top: 50%;
        transform: translateY(-35%) scale(0.9);
    }

    .banner-slogan-mobile{
        position: absolute;
        display: none;
        right: 0;
        bottom:0;
    }
}

@media screen and (max-width: 812px) {
    .about .container{
        position: initial;
    }

    .banner-slogan-pc{
        display: none;
    }

    .banner-slogan-mobile{
        position: absolute;
        right: 15px;
        bottom: 20px;
        display: inline-block;
        max-width: 300px;
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .about {
        height: 100vh;
        max-height: 500px;
        margin-top: 0;
        background-image: url("~@/assets/img/375/banner/main-bg.webp");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
    }
}
</style>