<template>
    <section class="position-relative d-flex overflow-hidden partner">
        <b-container>
            <h2 class="page-h1 mx-0 mb-0">{{ $t('Nav_partner') }}</h2>
            <b-row class="flex-wrap justify-content-start justify-content-sm-left">
                <b-col class="logo-wrapper">
                    <a target="_blank" class="partner-logo logo-acti" href="https://marketplace.intel.com/s/partner/a5S3b0000002nJNEAY/aira-corporation?language=en_US&wapkw=aira" rel="noopener noreferrer">
                        <img loading="lazy" src="@/assets/img/partner/intel_certificate.webp" alt="aira_人臉辨識系統廠商_城智科技合作夥伴_intel">
                    </a>
                </b-col>

                <b-col class="logo-wrapper">
                    <a target="_blank" class="partner-logo logo-axis" href="https://www.networkoptix.com/2021/11/17/airatrack-face-tracking-works-with-nx/" rel="noopener noreferrer">
                        <img loading="lazy" src="@/assets/img/partner/Nx_certificate.webp" alt="aira_人臉辨識系統廠商_城智科技合作夥伴_Nx">
                    </a>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
export default {
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.partner {
    padding: 10px 0 75px 0;
}

.logo-wrapper{
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

a.partner-logo {
    border: 1px solid #dddddd;
    display: block;
    width: 100%;
    text-align: center;
    box-shadow: 6px 6px 6px 0  rgba(0, 0, 0, 0.3);
    max-width: 100%;
    margin-bottom: 15px;
    transition: box-shadow ease 0.25s;
    padding: 0 15px;
}

a.partner-logo:hover{
    box-shadow: 6px 6px 16px 0 rgba(0, 0, 0, 0.7);
}

.partner-logo img{
    display: inline-block;
    max-width: 100%;
    object-fit: contain;
    object-position: center;
    height: 420px;
}

@media screen and (max-width: 812px) {
    .logo-wrapper{
        flex: 0 0 100%;
        max-width: 100%;

        // &:nth-child(2n) {
        //     padding-left: 7px;
        //     padding-right: 15px;
        // }

        // &:nth-child(2n + 1) {
        //     padding-left: 15px;
        //     padding-right: 7px;
        // }
    }
}


@media screen and (max-width: 576px) {
    .logo-wrapper {
        flex: 0 0 100%;
        max-width: 100%;
    }

    a.partner-logo{
        padding: 0 10px;
    }

    .partner-logo img{
        height: 300px;
    }
}
</style>